import React, { useEffect, useLayoutEffect } from "react";
import { CiMenuFries } from "react-icons/ci";
import { IoClose } from "react-icons/io5";
import { useOutsideClickHandler } from "../../../hooks/useOutsideClickHandler";
import { NAVBAR_ITEMS } from ".";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChangeLngButtonMain } from "../../ChangeLng/ChangeLngButtonMain";
import { motion } from "framer-motion"


export const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen, currentRef] = useOutsideClickHandler(false);
  const { t } = useTranslation();
  const toggleMenu = () => {
    if (window.innerWidth < 1024) {
      setIsMenuOpen((prevIsOpen) => !prevIsOpen);
    }
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  useLayoutEffect(() => {
    const handleScroll = () => {
      const navHeight = currentRef.current?.offsetHeight ?? 0;
      const scrollPosition = window.scrollY;
      if (scrollPosition > navHeight) {
        currentRef.current?.classList.add("bg-gray-900");
        currentRef.current?.classList.remove("bg-transparent");
      } else {
        currentRef.current?.classList.remove("bg-gray-900");
        currentRef.current?.classList.add("bg-transparent");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        closeMenu();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navList = (
    <ul className="flex flex-col lg:justify-center lg:items-center font-medium mt-4 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 bg-gray-800 md:bg-transparent">
      {NAVBAR_ITEMS.map((item) => (
        <li
          className="block py-2 pl-3 pr-4 md:border-0 md:p-0 text-white md:hover:text-main hover:bg-gray-700 hover:text-white md:hover:bg-transparent"
          aria-current="page"
          key={item.id}
          onClick={toggleMenu}
        >
          <Link
            to={item.path}
            className="flex items-center py-2 pl-3 pr-4 text-whit md:p-0"
            aria-current="page"
          >
            {t('navbar.' + item.name)}
          </Link>
        </li>
      ))}
      <li className="block gap-1 border-t-[1px] lg:border-t-0 lg:border-l-[1px] py-1 pl-3 border-main cursor-pointer pr-4 md:border-0 md:p-0 text-white md:hover:text-main hover:bg-gray-700 hover:text-white md:hover:bg-transparent"
        aria-current="page" onClick={toggleMenu}>
        <ChangeLngButtonMain initialLanguage="am" containerClassName="flex lg:justify-center py-2 pl-5 pr-4 items-center gap-2" />
      </li>
    </ul >
  );

  const navListMobile = (
    <motion.ul
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ type: 'spring', bounce: 0.3, duration: 1.5 }}
      className="flex flex-col lg:justify-center lg:items-center font-medium mt-4 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 bg-gray-800 md:bg-transparent">
      {NAVBAR_ITEMS.map((item) => (
        <motion.li
          initial={{ x: -170 }}
          animate={{ x: 0 }}
          transition={{ delay: item.id / 15, type: 'spring', bounce: 0.3 }}
          className="block py-2 pl-3 pr-4 md:border-0 md:p-0 text-white md:hover:text-main hover:bg-gray-700 hover:text-white md:hover:bg-transparent"
          aria-current="page"
          key={item.id}
          onClick={toggleMenu}
        >
          <Link
            to={item.path}
            className="flex items-center py-2 pl-3 pr-4 text-whit md:p-0"
            aria-current="page"
          >
            {t('navbar.' + item.name)}
          </Link>
        </motion.li>
      ))}
      <li className="block gap-1 border-t-[1px] lg:border-t-0 lg:border-l-[1px] py-1 pl-3 border-main cursor-pointer pr-4 md:border-0 md:p-0 text-white md:hover:text-main hover:bg-gray-700 hover:text-white md:hover:bg-transparent"
        aria-current="page" onClick={toggleMenu}>
        <ChangeLngButtonMain initialLanguage="am" containerClassName="flex lg:justify-center py-2 pl-5 pr-4 items-center gap-2" />
      </li>
    </motion.ul >
  );

  return (
    <nav
      ref={currentRef}
      className="border-b border-b-main bg-transparent w-full fixed z-50 transition duration-300"
    >
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto pl-4 pr-2.5 py-2.5 sm:p-2.5">
        <Link to='/' className="flex items-center">
          <img
            src={require("./../../../assets/logos/logo-white-small.webp")}
            className="w-12 h-full"
            alt="Online Invitation Studio Logo"
          />

          <span className="self-center text-lg sm:text-2xl font-semibold text-white tracking-widest pt-6">
            tudio
          </span>
        </Link>
        <div className="hidden md:block">{navList}</div>
        <button
          data-collapse-toggle="navbar-solid-bg"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden focus:outline-none text-gray-400 hover:bg-gray-700 focus:ring-gray-600"
          aria-controls="navbar-solid-bg"
          aria-expanded="false"
          onClick={toggleMenu}
        >
          <span className="sr-only">Open main menu</span>
          {isMenuOpen ? (
            <IoClose className="fill-white w-6 h-6" />
          ) : (
            <CiMenuFries className="fill-white w-6 h-6" />
          )}
        </button>
        {isMenuOpen && (
          <div
            className={`block w-full md:block md:w-auto`}
            id="navbar-solid-bg"
          >
            {navListMobile}
          </div>
        )}
      </div>
    </nav>
  );
};
