import React from 'react';

const CallButton: React.FC = () => {

    const phoneNumber = '+374 99 410 474';
    const handleClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

    return (
        <button onClick={handleClick} className='underline'>
            {phoneNumber}
        </button>
    );
};

export default CallButton;