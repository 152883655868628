import React from 'react';

interface ICustomButtonProps {
    type?: "button" | "submit" | "reset";
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => void;
    text: string;
    loadingButtonMessage?: string;
    buttonWrapper?:string
}

export const CustomButton: React.FC<ICustomButtonProps> = ({
    type = "button",
    className = "",
    disabled = false,
    loading = false,
    onClick,
    text,
    loadingButtonMessage,
    buttonWrapper
}) => {
    return (
        <div className={buttonWrapper}>
            <button
                type={type}
                className={`${className} ${loading ? 'opacity-50 pointer-events-none' : ''}`}
                disabled={disabled || loading}
                onClick={onClick}
            >
                {loading ? (
                    <span>{loadingButtonMessage}</span>
                ) : (
                    <span>{text}</span>
                )}
            </button>
        </div>
    );
};
