import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { Mainloader } from './components/Loaders/MainLoader/Mainloader';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
root.render(


  <React.StrictMode>
    <Suspense fallback={<Mainloader />}>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </Suspense>
  </React.StrictMode>
);
reportWebVitals();
