import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaGlobe } from "react-icons/fa6";

interface ChangeLngButtonProps {
    initialLanguage: string;
    containerClassName?: string;
}

export const ChangeLngButtonMain: React.FC<ChangeLngButtonProps> = ({
    initialLanguage,
    containerClassName,
}) => {
    const { i18n } = useTranslation();
    const savedLng = localStorage.getItem('i18nextLngMainSite') || 'am';
    const [currentLanguage, setCurrentLanguage] = useState(savedLng);

    const switchLanguage = (language: string) => {
        i18n.changeLanguage(language);
        setCurrentLanguage(language);
        localStorage.setItem('i18nextLngMainSite', language);
    };

    return (
        <div className={containerClassName} onClick={() =>
            switchLanguage(currentLanguage === "am" ? "en" : "am")
        }>
            <span>{currentLanguage === "am" ? "Հայ" : "en"}</span>
            <FaGlobe />
        </div>
    )
}