import { useState, useEffect, useRef, SetStateAction } from "react";

export function useOutsideClickHandler(
  initialState = false
): [
  boolean,
  React.Dispatch<SetStateAction<boolean>>,
  React.RefObject<HTMLDivElement>
] {
  const [isOpen, setIsOpen] = useState<boolean>(initialState);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onOutsideClick: EventListener | EventListenerObject = (
      event: Event
    ): void => {
      if (isOpen) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setIsOpen((previous: boolean) => !previous);
        }
      }
    };
    document.addEventListener("click", onOutsideClick, true);
    return () => {
      document.removeEventListener("click", onOutsideClick, true);
    };
  }, [isOpen]);

  return [isOpen, setIsOpen, ref];
}
