import React, {lazy} from "react";
import {Routes, Route} from "react-router-dom";
import {TestComponent} from "../components/Templates/TestForDevelopment/TestComponent";

const MainContainer = lazy(() =>
    import("../components/containers/MainContainer/MainContainer").then((module) => ({
        default: module.MainContainer,
    }))
);

const PageNotFound = lazy(() =>
    import("../components/sections/PageNotFound/PageNotFound").then((module) => ({default: module.PageNotFound}))
);

const Cards = lazy(() =>
    import("../components/Cards/Cards").then(
        (module) => ({
            default: module.Cards,
        })
    )
);

const Weblinks = lazy(() =>
    import("../components/Weblinks/Weblinks").then(
        (module) => ({
            default: module.Weblinks,
        })
    )
);

const Accessories = lazy(() =>
    import("../components/Accessories/Accessories").then(
        (module) => ({
            default: module.Accessories,
        })
    )
);

const Animations = lazy(() =>
    import("../components/Animations/Animations").then(
        (module) => ({
            default: module.Animations,
        })
    )
);

// ==================== TEMPLATES ====================

const Template001 = lazy(() =>
    import("../components/Templates/Template001/Template001").then((module) => ({default: module.Template001}))
);

const Template002 = lazy(() =>
    import("../components/Templates/Template002/Template002").then((module) => ({default: module.Template002}))
);

const Template003 = lazy(() =>
    import("../components/Templates/Template003/Template003").then((module) => ({default: module.Template003}))
);

const Template004 = lazy(() =>
    import("../components/Templates/Template004/Template004").then((module) => ({default: module.Template004}))
);

const Template005 = lazy(() =>
    import("../components/Templates/Template005/Template005").then((module) => ({default: module.Template005}))
);

const Template006 = lazy(() =>
    import("../components/Templates/Template006/Template006").then((module) => ({default: module.Template006}))
);

const Template007 = lazy(() =>
    import("../components/Templates/Template007/Template007").then((module) => ({default: module.Template007}))
);

const Template008 = lazy(() =>
    import("../components/Templates/Template008/Template008").then((module) => ({default: module.Template008}))
);

const Template009 = lazy(() =>
    import("../components/Templates/Template009/Template009").then((module) => ({default: module.Template009}))
);

const Template010 = lazy(() =>
    import("../components/Templates/Template010/Template010").then((module) => ({default: module.Template010}))
);
const Template011 = lazy(() =>
    import("../components/Templates/Template011/Template011").then((module) => ({default: module.Template011}))
);
const Template012 = lazy(() =>
    import("../components/Templates/Template012/Template012").then((module) => ({default: module.Template012}))
);
const Template013 = lazy(() =>
    import("../components/Templates/Template013/Template013").then((module) => ({default: module.Template013}))
);
const Template014 = lazy(() =>
    import("../components/Templates/Template014/Template014").then((module) => ({default: module.Template014}))
);
const Template015 = lazy(() =>
    import("../components/Templates/Template015/Template015").then((module) => ({default: module.Template015}))
);
// const Template016 = lazy(() =>
//     import("../components/Templates/Template016/Template016").then((module) => ({ default: module.Template016 }))
// );
const Template017 = lazy(() =>
    import("../components/Templates/Template017/Template017").then((module) => ({default: module.Template017}))
);

const Template018 = lazy(() =>
    import("../components/Templates/Template018/Template018").then((module) => ({default: module.Template018}))
);

const Template019 = lazy(() =>
    import("../components/Templates/Template019/Template019").then((module) => ({default: module.Template019}))
);
// const Template020 = lazy(() =>
//     import("../components/Templates/Template020/Template020").then((module) => ({default: module.Template020}))
// );
// ==================== ORDERS ====================

const Project01 = lazy(() =>
    import("../components/Wedding/GorArevik/Project01").then((module) => ({default: module.Project01}))
);

const Order001 = lazy(() =>
    import("../components/Orders/Wedding/Order001/Order001").then((module) => ({default: module.Order001}))
);

const Order002 = lazy(() =>
    import("../components/Orders/Wedding/Order002/Order002").then((module) => ({default: module.Order002}))
);

const Order003 = lazy(() =>
    import("../components/Orders/Wedding/Order003/Order003").then((module) => ({default: module.Order003}))
);

const Order004 = lazy(() =>
    import("../components/Orders/Wedding/Order004/Order004").then((module) => ({default: module.Order004}))
);

const Order005 = lazy(() =>
    import("../components/Orders/Wedding/Order005/Order005").then((module) => ({default: module.Order005}))
)

const Order006 = lazy(() =>
    import("../components/Orders/Wedding/Order006/Order006").then((module) => ({default: module.Order006}))
)

const Order007 = lazy(() =>
    import("../components/Orders/Wedding/Order007/Order007").then((module) => ({default: module.Order007}))
)

const Order008 = lazy(() =>
    import("../components/Orders/Wedding/Order008/Order008").then((module) => ({default: module.Order008}))
)

const Order009 = lazy(() =>
    import("../components/Orders/Wedding/Order009/Order009").then((module) => ({default: module.Order009}))
)
const Order010 = lazy(() =>
    import("../components/Orders/Wedding/Order010/Order010").then((module) => ({default: module.Order010}))
)
const Order011 = lazy(() =>
    import("../components/Orders/Wedding/Order011/Order011").then((module) => ({default: module.Order011}))
)
const Order012 = lazy(() =>
    import("../components/Orders/Engagement/Order012/Order012").then((module) => ({default: module.Order012}))
)
const Order013 = lazy(() =>
    import("../components/Orders/Wedding/Order013/Order013").then((module) => ({default: module.Order013}))
)
const Order014 = lazy(() =>
    import("../components/Orders/Wedding/Order014/Order014").then((module) => ({default: module.Order014}))
);
const Order015 = lazy(() =>
    import("../components/Orders/Wedding/Order015/Order015").then((module) => ({default: module.Order015}))
);

const Order016 = lazy(() =>
    import("../components/Orders/Wedding/Order016/Order016").then((module) => ({default: module.Order016}))
);

const Order017 = lazy(() =>
    import("../components/Orders/Wedding/Order017/Order017").then((module) => ({default: module.Order017}))
);

const Order018 = lazy(() =>
    import("../components/Orders/Wedding/Order018/Order018").then((module) => ({default: module.Order018}))
);

const Order019 = lazy(() =>
    import("../components/Orders/Wedding/Order019/Order019").then((module) => ({default: module.Order019}))
);

const Order020 = lazy(() =>
    import("../components/Orders/Wedding/Order020/Order020").then((module) => ({default: module.Order020}))
);

const Order021 = lazy(() =>
    import("../components/Orders/Wedding/Order021/Order021").then((module) => ({default: module.Order021}))
);

const Order022 = lazy(() =>
    import("../components/Orders/Wedding/Order022/Order022").then((module) => ({default: module.Order022}))
);

const Order023 = lazy(() =>
    import("../components/Orders/Wedding/Order023/Order023").then((module) => ({default: module.Order023}))
);

const Order024 = lazy(() =>
    import("../components/Orders/Wedding/Order024/Order024").then((module) => ({default: module.Order024}))
);

const Order025 = lazy(() =>
    import("../components/Orders/Baptism/Order025/Order025").then((module) => ({default: module.Order025}))
);

const Order026 = lazy(() =>
    import("../components/Orders/Event/Order026/Order026").then((module) => ({default: module.Order026}))
);

const Order027 = lazy(() =>
    import("../components/Orders/Wedding/Order027/Order027").then((module) => ({default: module.Order027}))
);

const Order028 = lazy(() =>
    import("../components/Orders/Wedding/Order028/Order028").then((module) => ({default: module.Order028}))
);

const Order029 = lazy(() =>
    import("../components/Orders/Wedding/Order029/Order029").then((module) => ({default: module.Order029}))
);
const Order030 = lazy(() =>
    import("../components/Orders/Wedding/Order030/Order030").then((module) => ({default: module.Order030}))
);
const Order031 = lazy(() =>
    import("../components/Orders/Wedding/Order031/Order031").then((module) => ({default: module.Order031}))
);
// const Order032 = lazy(() =>
//     import("../components/Orders/Wedding/Order032/Order032").then((module) => ({ default: module.Order032 }))
// );
const Order033 = lazy(() =>
    import("../components/Orders/Wedding/Order033/Order033").then((module) => ({default: module.Order033}))
);

const Order034 = lazy(() =>
    import("../components/Orders/Wedding/Order034/Order034").then((module) => ({default: module.Order034}))
);
const Order035 = lazy(() =>
    import("../components/Orders/Wedding/Order035/Order035").then((module) => ({default: module.Order035}))
);
const Order036 = lazy(() =>
    import("../components/Orders/Wedding/Order036/Order036").then((module) => ({default: module.Order036}))
);
const Order037 = lazy(() =>
    import("../components/Orders/Baptism/Order037/Order037").then((module) => ({default: module.Order037}))
);
const Order038 = lazy(() =>
    import("../components/Orders/Wedding/Order038/Order038").then((module) => ({default: module.Order038}))
);
const Order039 = lazy(() =>
    import("../components/Orders/Wedding/Order039/Order039").then((module) => ({default: module.Order039}))
);
const Order040 = lazy(() =>
    import("../components/Orders/Wedding/Order040/Order040").then((module) => ({default: module.Order040}))
);
const Order041 = lazy(() =>
    import("../components/Orders/Wedding/Order041/Order041").then((module) => ({default: module.Order041}))
);
const Order042 = lazy(() =>
    import("../components/Orders/Wedding/Order042/Order042").then((module) => ({default: module.Order042}))
);
const Order043 = lazy(() =>
    import("../components/Orders/Baptism/Order043/Order043").then((module) => ({default: module.Order043}))
);
const Order044 = lazy(() =>
    import("../components/Orders/Wedding/Order044/Order044").then((module) => ({default: module.Order044}))
);
const Order045 = lazy(() =>
    import("../components/Orders/Wedding/Order045/Order045").then((module) => ({default: module.Order045}))
);
const Order046 = lazy(() =>
    import("../components/Orders/Wedding/Order046/Order046").then((module) => ({default: module.Order046}))
);
const Order047 = lazy(() =>
    import("../components/Orders/Wedding/Order047/Order047").then((module) => ({default: module.Order047}))
);
const Order048 = lazy(() =>
    import("../components/Orders/Wedding/Order048/Order048").then((module) => ({default: module.Order048}))
);
const Order049 = lazy(() =>
    import("../components/Orders/Wedding/Order049/Order049").then((module) => ({default: module.Order049}))
);
const Order050 = lazy(() =>
    import("../components/Orders/Wedding/Order050/Order050").then((module) => ({default: module.Order050}))
);
const Order051 = lazy(() =>
    import("../components/Orders/Wedding/Order051/Order051").then((module) => ({default: module.Order051}))
);
const Order053 = lazy(() =>
    import("../components/Orders/Wedding/Order053/Order053").then((module) => ({default: module.Order053}))
);
const Order054 = lazy(() =>
    import("../components/Orders/Wedding/Order054/Order054").then((module) => ({default: module.Order054}))
);
const Order055 = lazy(() =>
    import("../components/Orders/Wedding/Order055/Order055").then((module) => ({default: module.Order055}))
);
const Order056 = lazy(() =>
    import("../components/Orders/Wedding/Order056/Order056").then((module) => ({default: module.Order056}))
);
const Order057 = lazy(() =>
    import("../components/Orders/Wedding/Order057/Order057").then((module) => ({default: module.Order057}))
);
const Order058 = lazy(() =>
    import("../components/Orders/Wedding/Order058/Order058").then((module) => ({default: module.Order058}))
);
const Order059 = lazy(() =>
    import("../components/Orders/Wedding/Order059/Order059").then((module) => ({default: module.Order059}))
);

const Order060 = lazy(() =>
    import("../components/Orders/Wedding/Order060/Order060").then((module) => ({default: module.Order060}))
);
const Order061 = lazy(() =>
    import("../components/Orders/Wedding/Order061/Order061").then((module) => ({default: module.Order061}))
);
const Order062 = lazy(() =>
    import("../components/Orders/Wedding/Order062/Order062").then((module) => ({default: module.Order062}))
);
const Order063 = lazy(() =>
    import("../components/Orders/Wedding/Order063/Order063").then((module) => ({default: module.Order063}))
);
const Order063en = lazy(() =>
    import("../components/Orders/Wedding/Order063/Order063en").then((module) => ({default: module.Order063en}))
);
const Order064 = lazy(() =>
    import("../components/Orders/Wedding/Order064/Order064").then((module) => ({default: module.Order064}))
);
const Order065 = lazy(() =>
    import("../components/Orders/Wedding/Order065/Order065").then((module) => ({default: module.Order065}))
);
const Order066 = lazy(() =>
    import("../components/Orders/Wedding/Order066/Order066").then((module) => ({default: module.Order066}))
);
const Order067 = lazy(() =>
    import("../components/Orders/Wedding/Order067/Order067").then((module) => ({default: module.Order067}))
);
const Order068 = lazy(() =>
    import("../components/Orders/Wedding/Order068/Order068").then((module) => ({default: module.Order068}))
);
const Order069 = lazy(() =>
    import("../components/Orders/Wedding/Order069/Order069").then((module) => ({default: module.Order069}))
);
const Order070 = lazy(() =>
    import("../components/Orders/Wedding/Order070/Order070").then((module) => ({default: module.Order070}))
);
const Order071 = lazy(() =>
    import("../components/Orders/Wedding/Order071/Order071").then((module) => ({default: module.Order071}))
);
const Order072 = lazy(() =>
    import("../components/Orders/Wedding/Order072/Order072").then((module) => ({default: module.Order072}))
);
const Order073 = lazy(() =>
    import("../components/Orders/Wedding/Order073/Order073").then((module) => ({default: module.Order073}))
);
const Order074 = lazy(() =>
    import("../components/Orders/Wedding/Order074/Order074").then((module) => ({default: module.Order074}))
);
const Order075 = lazy(() =>
    import("../components/Orders/Wedding/Order075/Order075").then((module) => ({default: module.Order075}))
);
const Order076 = lazy(() =>
    import("../components/Orders/Wedding/Order076/Order076").then((module) => ({default: module.Order076}))
);
const Order077 = lazy(() =>
    import("../components/Orders/Wedding/Order077/Order077").then((module) => ({default: module.Order077}))
);
const Order078 = lazy(() =>
    import("../components/Orders/Wedding/Order078/Order078").then((module) => ({default: module.Order078}))
);
const Order079 = lazy(() =>
    import("../components/Orders/Wedding/Order079/Order079").then((module) => ({default: module.Order079}))
);
const Order080 = lazy(() =>
    import("../components/Orders/Wedding/Order080/Order080").then((module) => ({default: module.Order080}))
);
const Order081 = lazy(() =>
    import("../components/Orders/Wedding/Order081/Order081").then((module) => ({default: module.Order081}))
);
const Order082 = lazy(() =>
    import("../components/Orders/Wedding/Order082/Order082").then((module) => ({default: module.Order082}))
);
const Order083 = lazy(() =>
    import("../components/Orders/Wedding/Order083/Order083").then((module) => ({default: module.Order083}))
);
const Order083en = lazy(() =>
    import("../components/Orders/Wedding/Order083/Order083en").then((module) => ({default: module.Order083en}))
);
const Order084 = lazy(() =>
    import("../components/Orders/Wedding/Order084/Order084").then((module) => ({default: module.Order084}))
);
const Order085 = lazy(() =>
    import("../components/Orders/Wedding/Order085/Order085").then((module) => ({default: module.Order085}))
);
const Order086 = lazy(() =>
    import("../components/Orders/Wedding/Order086/Order086").then((module) => ({default: module.Order086}))
);
const Order087 = lazy(() =>
    import("../components/Orders/Wedding/Order087/Order087").then((module) => ({default: module.Order087}))
);
const Order088 = lazy(() =>
    import("../components/Orders/Wedding/Order088/Order088").then((module) => ({default: module.Order088}))
);
const Order089 = lazy(() =>
    import("../components/Orders/ToothParty/Order089/Order089").then((module) => ({default: module.Order089}))
);
const Order090 = lazy(() =>
    import("../components/Orders/Engagement/Order090/Order090").then((module) => ({default: module.Order090}))
);
// const Order091 = lazy(() =>
//     import("../components/Orders/Wedding/Order091/Order091").then((module) => ({default: module.Order091}))
// );
const Order092 = lazy(() =>
    import("../components/Orders/Wedding/Order092/Order092").then((module) => ({default: module.Order092}))
);
const Order093 = lazy(() =>
    import("../components/Orders/Wedding/Order093/Order093").then((module) => ({default: module.Order093}))
);
const Order094 = lazy(() =>
    import("../components/Orders/Wedding/Order094/Order094").then((module) => ({default: module.Order094}))
);
const Order095 = lazy(() =>
    import("../components/Orders/Wedding/Order095/Order095").then((module) => ({default: module.Order095}))
);
const Order096 = lazy(() =>
    import("../components/Orders/Wedding/Order096/Order096").then((module) => ({default: module.Order096}))
);
const Order097 = lazy(() =>
    import("../components/Orders/Wedding/Order097/Order097").then((module) => ({default: module.Order097}))
);
const Order098 = lazy(() =>
    import("../components/Orders/Wedding/Order098/Order098").then((module) => ({default: module.Order098}))
);
const Order098ru = lazy(() =>
    import("../components/Orders/Wedding/Order098/Order098ru").then((module) => ({default: module.Order098ru}))
);
const Order099 = lazy(() =>
    import("../components/Orders/Wedding/Order099/Order099").then((module) => ({default: module.Order099}))
);
const Order100 = lazy(() =>
    import("../components/Orders/Wedding/Order100/Order100").then((module) => ({default: module.Order100}))
);
const Order101 = lazy(() =>
    import("../components/Orders/Wedding/Order101/Order101").then((module) => ({default: module.Order101}))
);
const Order101Ru = lazy(() =>
    import("../components/Orders/Wedding/Order101/Order101Ru").then((module) => ({default: module.Order101Ru}))
);
const Order102 = lazy(() =>
    import("../components/Orders/Wedding/Order102/Order102").then((module) => ({default: module.Order102}))
);
const Order103 = lazy(() =>
    import("../components/Orders/Wedding/Order103/Order103").then((module) => ({default: module.Order103}))
);
const Order104 = lazy(() =>
    import("../components/Orders/Wedding/Order104/Order104").then((module) => ({default: module.Order104}))
);
const Order105 = lazy(() =>
    import("../components/Orders/Wedding/Order105/Order105").then((module) => ({default: module.Order105}))
);
const Order106 = lazy(() =>
    import("../components/Orders/Wedding/Order106/Order106").then((module) => ({default: module.Order106}))
);
const Order106en = lazy(() =>
    import("../components/Orders/Wedding/Order106/Order106en").then((module) => ({default: module.Order106en}))
);
const Order107 = lazy(() =>
    import("../components/Orders/Wedding/Order107/Order107").then((module) => ({default: module.Order107}))
);
const Order107en = lazy(() =>
    import("../components/Orders/Wedding/Order107/Order107en").then((module) => ({default: module.Order107en}))
);
const Order108 = lazy(() =>
    import("../components/Orders/Wedding/Order108/Order108").then((module) => ({default: module.Order108}))
)
const Order109 = lazy(() =>
    import("../components/Orders/Wedding/Order109/Order109").then((module) => ({default: module.Order109}))
)
const Order110 = lazy(() =>
    import("../components/Orders/Wedding/Order110/Order110").then((module) => ({default: module.Order110}))
)
const Order111 = lazy(() =>
    import("../components/Orders/Wedding/Order111/Order111").then((module) => ({default: module.Order111}))
)
const Order112 = lazy(() =>
    import("../components/Orders/Wedding/Order112/Order112").then((module) => ({default: module.Order112}))
)
const Order113 = lazy(() =>
    import("../components/Orders/Wedding/Order113/Order113").then((module) => ({default: module.Order113}))
)
const Order114 = lazy(() =>
    import("../components/Orders/Wedding/Order114/Order114").then((module) => ({default: module.Order114}))
)
const Order115 = lazy(() =>
    import("../components/Orders/Wedding/Order115/Order115").then((module) => ({default: module.Order115}))
)
const Order116 = lazy(() =>
    import("../components/Orders/Wedding/Order116/Order116").then((module) => ({default: module.Order116}))
)
const Order117 = lazy(() =>
    import("../components/Orders/Wedding/Order117/Order117").then((module) => ({default: module.Order117}))
)
const Order118 = lazy(() =>
    import("../components/Orders/Wedding/Order118/Order118").then((module) => ({default: module.Order118}))
)
const Order119 = lazy(() =>
    import("../components/Orders/Wedding/Order119/Order119").then((module) => ({default: module.Order119}))
)
const Order120 = lazy(() =>
    import("../components/Orders/Wedding/Order120/Order120").then((module) => ({default: module.Order120}))
)
const Order121 = lazy(() =>
    import("../components/Orders/Wedding/Order121/Order121").then((module) => ({default: module.Order121}))
)
const Order122 = lazy(() =>
    import("../components/Orders/Wedding/Order122/Order122").then((module) => ({default: module.Order122}))
)
const Order123 = lazy(() =>
    import("../components/Orders/Wedding/Order123/Order123").then((module) => ({default: module.Order123}))
)
const Order124 = lazy(() =>
    import("../components/Orders/Wedding/Order124/Order124").then((module) => ({default: module.Order124}))
)
const Order125 = lazy(() =>
    import("../components/Orders/Wedding/Order125/Order125").then((module) => ({default: module.Order125}))
)
const Order126 = lazy(() =>
    import("../components/Orders/Wedding/Order126/Order126").then((module) => ({default: module.Order126}))
)
const Order127 = lazy(() =>
    import("../components/Orders/Wedding/Order127/Order127").then((module) => ({default: module.Order127}))
)
const Order128 = lazy(() =>
    import("../components/Orders/Wedding/Order128/Order128").then((module) => ({default: module.Order128}))
)
const Order128ru = lazy(() =>
    import("../components/Orders/Wedding/Order128/Order128ru").then((module) => ({default: module.Order128ru}))
)
const Order129 = lazy(() =>
    import("../components/Orders/Wedding/Order129/Order129").then((module) => ({default: module.Order129}))
)
const Order130 = lazy(() =>
    import("../components/Orders/Wedding/Order130/Order130").then((module) => ({default: module.Order130}))
)
const Order131 = lazy(() =>
    import("../components/Orders/Wedding/Order131/Order131").then((module) => ({default: module.Order131}))
)
const Order132 = lazy(() =>
    import("../components/Orders/Wedding/Order132/Order132").then((module) => ({default: module.Order132}))
)
const Order133 = lazy(() =>
    import("../components/Orders/Baptism/Order133/Order133").then((module) => ({default: module.Order133}))
)
const Order134 = lazy(() =>
    import("../components/Orders/Wedding/Order134/Order134").then((module) => ({default: module.Order134}))
)
const Order135 = lazy(() =>
    import("../components/Orders/Wedding/Order135/Order135").then((module) => ({default: module.Order135}))
)
const Order136 = lazy(() =>
    import("../components/Orders/Wedding/Order136/Order136").then((module) => ({default: module.Order136}))
)
const Order137 = lazy(() =>
    import("../components/Orders/Wedding/Order137/Order137").then((module) => ({default: module.Order137}))
)
const Order138 = lazy(() =>
    import("../components/Orders/Wedding/Order138/Order138").then((module) => ({default: module.Order138}))
)
const Order138en = lazy(() =>
    import("../components/Orders/Wedding/Order138/Order138en").then((module) => ({default: module.Order138en}))
)
const Order139 = lazy(() =>
    import("../components/Orders/Wedding/Order139/Order139").then((module) => ({default: module.Order139}))
)
const Order139ru = lazy(() =>
    import("../components/Orders/Wedding/Order139/Order139ru").then((module) => ({default: module.Order139ru}))
)
const Order140 = lazy(() =>
    import("../components/Orders/Wedding/Order140/Order140").then((module) => ({default: module.Order140}))
)
const Order141 = lazy(() =>
    import("../components/Orders/Wedding/Order141/Order141").then((module) => ({default: module.Order141}))
)
const Order142 = lazy(() =>
    import("../components/Orders/Wedding/Order142/Order142").then((module) => ({default: module.Order142}))
)
const Order143= lazy(() =>
    import("../components/Orders/Wedding/Order143/Order143").then((module) => ({default: module.Order143}))
)
const Order144= lazy(() =>
    import("../components/Orders/Event/Order144/Order144").then((module) => ({default: module.Order144}))
)
const Order145= lazy(() =>
    import("../components/Orders/Wedding/Order145/Order145").then((module) => ({default: module.Order145}))
)
const Order146= lazy(() =>
    import("../components/Orders/Wedding/Order146/Order146").then((module) => ({default: module.Order146}))
)
const Order147 = lazy(() =>
    import("../components/Orders/Wedding/Order147/Order147").then((module) => ({default: module.Order147}))
)
const Order148 = lazy(() =>
    import("../components/Orders/Wedding/Order148/Order148").then((module) => ({default: module.Order148}))
)
const Order150 = lazy(() =>
    import("../components/Orders/Engagement/Order150/Order150").then((module) => ({default: module.Order150}))
)
export const PageRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<MainContainer/>}></Route>
            <Route path="/cards" element={<Cards/>}></Route>
            <Route path="/animations" element={<Animations/>}></Route>
            <Route path="/weblink" element={<Weblinks/>}></Route>
            <Route path="/accessories" element={<Accessories/>}></Route>
            <Route path="/wedding/gor-arevik" element={<Project01/>}></Route>
            <Route path="/templates/1001" element={<Template001/>}></Route>
            <Route path="/templates/1002" element={<Template002/>}></Route>
            <Route path="/templates/1003" element={<Template003/>}></Route>
            <Route path="/templates/1004" element={<Template004/>}></Route>
            <Route path="/templates/1005" element={<Template005/>}></Route>
            <Route path="/templates/1006" element={<Template006/>}></Route>
            <Route path="/templates/1007" element={<Template007/>}></Route>
            <Route path="/templates/1008" element={<Template008/>}></Route>
            <Route path="/templates/1009" element={<Template009/>}></Route>
            <Route path="/templates/1010" element={<Template010/>}></Route>
            <Route path="/templates/1011" element={<Template011/>}></Route>
            <Route path="/templates/1012" element={<Template012/>}></Route>
            <Route path="/templates/1013" element={<Template013/>}></Route>
            <Route path="/templates/1014" element={<Template014/>}></Route>
            <Route path="/templates/1015" element={<Template015/>}></Route>
            {/* <Route path="/templates/1016" element={<Template016 />}></Route> */}
            <Route path="/templates/1017" element={<Template017/>}></Route>
            <Route path="/templates/1018" element={<Template018/>}></Route>
            <Route path="/templates/1019" element={<Template019/>}></Route>
            {/* <Route path="/templates/1020" element={<Template020/>}></Route> */}
            {/* Test Route for Form */}
            <Route path="/templates/test-private" element={<TestComponent/>}></Route>
            {/* Test Route for Form */}

            <Route path="/wedding/ruben-sofi" element={<Order001/>}></Route>
            <Route path="/wedding/harutyun-amalya" element={<Order002/>}></Route>
            <Route path="/wedding/hayk-mariam" element={<Order003/>}></Route>
            <Route path="/wedding/garo-palig" element={<Order004/>}></Route>
            <Route path="/wedding/karen-ani" element={<Order005/>}></Route>
            <Route path="/wedding/shavarsh-ev-aida" element={<Order006/>}></Route>
            <Route path="/wedding/sargis-ev-piruz" element={<Order007/>}></Route>
            <Route path="/wedding/sergey-mariam" element={<Order008/>}></Route>
            <Route path="/wedding/rafo-syuzi" element={<Order009/>}></Route>
            <Route path="/wedding/tigran-anahit" element={<Order010/>}></Route>
            <Route path="/wedding/yuri-elizabeth" element={<Order011/>}></Route>
            <Route path="/engagement/vram-aghavni" element={<Order012/>}></Route>
            <Route path="/wedding/tigran-ina" element={<Order013/>}></Route>
            <Route path="/wedding/karen-sirusho" element={<Order014/>}></Route>
            <Route path="/wedding/mihran-rima" element={<Order015/>}></Route>
            <Route path="/wedding/john-nelly" element={<Order016/>}></Route>
            <Route path="/wedding/tigran-sona" element={<Order017/>}></Route>
            <Route path="/wedding/serzh-yeghegni" element={<Order018/>}></Route>
            <Route path="/wedding/artsruni-meri" element={<Order019/>}></Route>
            <Route path="/wedding/hrach-ev-ani" element={<Order020/>}></Route>
            <Route path="/wedding/grigor-veronica" element={<Order021/>}></Route>
            <Route path="/wedding/babken-christina" element={<Order022/>}></Route>
            <Route path="/wedding/samvel-mery" element={<Order023/>}></Route>
            <Route path="/wedding/andranik-elina" element={<Order024/>}></Route>
            <Route path="/mkrtutyun/emily" element={<Order025/>}></Route>
            <Route path="/event/alluria" element={<Order026/>}></Route>
            <Route path="/wedding/karen-gayane" element={<Order027/>}></Route>
            <Route path="/wedding/erik-syuzan" element={<Order028/>}></Route>
            <Route path="/wedding/azat-tamara" element={<Order029/>}></Route>
            <Route path="/wedding/karo-manik" element={<Order030/>}></Route>
            <Route path="/wedding/arsen-luiza" element={<Order031/>}></Route>
            {/* <Route path="/wedding/demo032" element={<Order032 />}></Route> */}
            <Route path="/wedding/erik-amalya" element={<Order033/>}></Route>
            <Route path="/wedding/alen-lia" element={<Order034/>}></Route>
            <Route path="/wedding/khachatur-astghik" element={<Order035/>}></Route>
            <Route path="/wedding/hoso-maga" element={<Order036/>}></Route>
            <Route path="/mkrtutyun-taredardz/harutyunyanner" element={<Order037/>}></Route>
            <Route path="/wedding/nerses-manush" element={<Order038/>}></Route>
            <Route path="/wedding/qajo-anjela" element={<Order039/>}></Route>
            <Route path="/wedding/smbat-silvi" element={<Order040/>}></Route>
            <Route path="/wedding/artyom-aghunik" element={<Order041/>}></Route>
            <Route path="/wedding/arman-shushanna" element={<Order042/>}></Route>
            <Route path="/mkrtutyun/gabrelianner-melkonianner" element={<Order043/>}></Route>
            <Route path="/wedding/karen-susanna" element={<Order044/>}></Route>
            <Route path="/wedding/roland-ani" element={<Order046/>}></Route>
            <Route path="/wedding/narek-susanna" element={<Order045/>}></Route>
            <Route path="/wedding/andreas-liana" element={<Order047/>}></Route>
            <Route path="/wedding/smbat-tatev" element={<Order048/>}></Route>
            <Route path="/wedding/eric-elina" element={<Order049/>}></Route>
            <Route path="/wedding/mikael-marina" element={<Order050/>}></Route>
            <Route path="/wedding/erik-tsovinar" element={<Order051/>}></Route>
            <Route path="/wedding/ishkhan-anna" element={<Order053/>}></Route>
            <Route path="/wedding/edgar-sofya" element={<Order054/>}></Route>
            <Route path="/wedding/aram-amelie" element={<Order056/>}></Route>
            <Route path="/wedding/hovsep-gayane" element={<Order057/>}></Route>
            <Route path="/wedding/hayk-anna" element={<Order055/>}></Route>
            <Route path="/wedding/levon-diana" element={<Order058/>}></Route>
            <Route path="/wedding/hovhannes-haykuhi" element={<Order059/>}></Route>
            <Route path="/wedding/edgar-hasmik" element={<Order060/>}></Route>
            <Route path="/wedding/davit-diana" element={<Order061/>}></Route>
            <Route path="/wedding/vladimir-rima" element={<Order062/>}></Route>
            <Route path="/wedding/harout-jessica" element={<Order063/>}></Route>
            <Route path="/wedding/harout-jessica/en" element={<Order063en/>}></Route>
            <Route path="/wedding/sergey-anahit" element={<Order064/>}></Route>
            <Route path="/wedding/vahe-armine" element={<Order065/>}></Route>
            <Route path="/wedding/rafayel-hasmik" element={<Order066/>}></Route>
            <Route path="/wedding/mihran-masha" element={<Order067/>}></Route>
            <Route path="/wedding/gevorg-monika" element={<Order068/>}></Route>
            <Route path="/wedding/khachatur-diana" element={<Order069/>}></Route>
            <Route path="/wedding/gnel-zhenya" element={<Order070/>}></Route>
            <Route path="/wedding/gagik-syuzanna" element={<Order071/>}></Route>
            <Route path="/wedding/gegham-lusine" element={<Order072/>}></Route>
            <Route path="/wedding/edgar-klara" element={<Order073/>}></Route>
            <Route path="/wedding/arsen-karine" element={<Order074/>}></Route>
            <Route path="/wedding/volodya-galina" element={<Order075/>}></Route>
            <Route path="/wedding/aram-christina" element={<Order076/>}></Route>
            <Route path="/wedding/shant-salpy" element={<Order077/>}></Route>
            <Route path="/wedding/hayk-nadya" element={<Order078/>}></Route>
            <Route path="/wedding/melqon-syuzanna" element={<Order079/>}></Route>
            <Route path="/wedding/armen-donara" element={<Order080/>}></Route>
            <Route path="/wedding/makich-monika" element={<Order081/>}></Route>
            <Route path="/wedding/gevorg-ashkhen" element={<Order082/>}></Route>
            <Route path="/wedding/ashot-yana" element={<Order083/>}></Route>
            <Route path="/wedding/ashot-yana/en" element={<Order083en/>}></Route>
            <Route path="/wedding/hayk-sona" element={<Order084/>}></Route>
            <Route path="/wedding/hayk-luiza" element={<Order085/>}></Route>
            <Route path="/wedding/gor-ev-elen" element={<Order086/>}></Route>
            <Route path="/wedding/karen-diana" element={<Order087/>}></Route>
            <Route path="/wedding/hoso-karine" element={<Order088/>}></Route>
            <Route path="/tooth-party/demo089" element={<Order089/>}></Route>
            <Route path="/engagement/samvel-ofelya" element={<Order090/>}></Route>
            {/* <Route path="/wedding/petros-lilit" element={<Order091/>}></Route> */}
            <Route path="/wedding/sos-sirun" element={<Order092/>}></Route>
            <Route path="/wedding/armen-diana" element={<Order093/>}></Route>
            <Route path="/wedding/david-luiza" element={<Order094/>}></Route>
            <Route path="/wedding/vardan-lyuba" element={<Order095/>}></Route>
            <Route path="/wedding/david-liana" element={<Order096/>}></Route>
            <Route path="/wedding/aghvan-michelle" element={<Order097/>}></Route>
            <Route path="/wedding/artur-nane" element={<Order098/>}></Route>
            <Route path="/wedding/artur-nane/ru" element={<Order098ru/>}></Route>
            <Route path="/wedding/gagik-meri" element={<Order099/>}></Route>
            <Route path="/wedding/rubik-amalya" element={<Order100/>}></Route>
            <Route path="/wedding/sergey-silvia" element={<Order101 />}></Route>
            <Route path="/wedding/sergey-silvia/ru" element={<Order101Ru />}></Route>
            <Route path="/wedding/albert-sofya" element={<Order102 />}></Route>
            <Route path="/wedding/georgi-anna" element={<Order103 />}></Route>
            <Route path="/wedding/arman-nelly" element={<Order104 />}></Route>
            <Route path="/wedding/arman-lilya" element={<Order105 />}></Route>
            <Route path="/wedding/tadevos-anna" element={<Order106 />}></Route>
            <Route path="/wedding/tadevos-anna/en" element={<Order106en />}></Route>
            <Route path="/wedding/mels-ruzanna" element={<Order107 />}></Route>
            <Route path="/wedding/mels-ruzanna/en" element={<Order107en />}></Route>
            <Route path="/wedding/volodya-maria" element={<Order108 />}></Route>
            <Route path="/wedding/vigen-ani" element={<Order109 />}></Route>
            <Route path="/wedding/vaso-maria" element={<Order110 />}></Route>
            <Route path="/wedding/gagik-srbuhi" element={<Order111 />}></Route>
            <Route path="/wedding/hovhannes-varduhi" element={<Order112 />}></Route>
            <Route path="/wedding/armen-sona" element={<Order113 />}></Route>
            <Route path="/wedding/edgar-evelina" element={<Order114 />}></Route>
            <Route path="/wedding/gevorg-naira" element={<Order115 />}></Route>
            <Route path="/wedding/gewedding" element={<Order116 />}></Route>
            <Route path="/wedding/robert-elen" element={<Order117 />}></Route>
            <Route path="/wedding/edmond-dayana" element={<Order118 />}></Route>

            <Route path="/wedding/suren-ariana" element={<Order119 />}></Route>
            <Route path="/wedding/demo120" element={<Order120 />}></Route>
            <Route path="/wedding/gevorg-mery" element={<Order121 />}></Route>
            <Route path="/wedding/sargis-anushik" element={<Order122 />}></Route>
            <Route path="/wedding/armen-armine" element={<Order123 />}></Route>
            <Route path="/wedding/nubar-seda" element={<Order124 />}></Route>
            <Route path="/wedding/vardan-karina" element={<Order125 />}></Route>
            <Route path="/wedding/aper-mariam" element={<Order126 />}></Route>
            <Route path="/wedding/tigran-donara" element={<Order127 />}></Route>
            <Route path="/wedding/artsrun-anahit" element={<Order128 />}></Route>
            <Route path="/wedding/artsrun-anahit/ru" element={<Order128ru />}></Route>
            <Route path="/wedding/narek-diana" element={<Order129 />}></Route>
            <Route path="/wedding/armen-anahit" element={<Order130 />}></Route>
            <Route path="/wedding/toros-ada" element={<Order131 />}></Route>
            <Route path="/wedding/gor-lilit" element={<Order132 />}></Route>
            <Route path="/mkrtutyun/voskanyanner" element={<Order133 />}></Route>
            <Route path="/wedding/gagik-ani" element={<Order134 />}></Route>
            <Route path="/wedding/gegham-eleonora" element={<Order135 />}></Route>
            <Route path="/wedding/gagik-mery" element={<Order136 />}></Route>
            <Route path="/wedding/levon-anna" element={<Order137 />}></Route>
            <Route path="/wedding/serob-naira" element={<Order138 />}></Route>
            <Route path="/wedding/serob-naira/en" element={<Order138en />}></Route>
            <Route path="/wedding/gor-lusine" element={<Order139 />}></Route>
            <Route path="/wedding/gor-lusine/ru" element={<Order139ru />}></Route>
            <Route path="/wedding/robert-liana" element={<Order140 />}></Route>
            <Route path="/wedding/aram-ani" element={<Order141 />}></Route>
            <Route path="/wedding/aram-armine" element={<Order142 />}></Route>
            <Route path="/wedding/vahan-tina" element={<Order143 />}></Route>
            <Route path="/event/ashas-corner" element={<Order144 />}></Route>
            <Route path="/wedding/sargis-knarik" element={<Order145 />}></Route>
            <Route path="/wedding/ashot-armine" element={<Order146 />}></Route>
            <Route path="/wedding/hamlet-shushan" element={<Order147 />}></Route>
            <Route path="/wedding/demo148" element={<Order148 />}></Route>
            <Route path="/engagement/vahan-nona" element={<Order150 />}></Route>

            <Route path="*" element={<PageNotFound/>}/>
        </Routes>
    );
};
