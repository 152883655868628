import React from "react";
import { PiPhoneCallLight } from "react-icons/pi";
import CallButton from "../../CallButton/CallButton";
import { FaInstagram, FaWhatsapp, FaTelegramPlane } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const openWhatsApp = () => {
    window.location.href = 'https://wa.me/+37499410474';
  };

  const openTelegram = () => {
    window.location.href = 'tg://resolve?domain=+37499410474';
  };
  const { t } = useTranslation();
  return (
    <footer className="bg-gray-900">
      <div className=" w-full p-4">
        <hr className="my-6 sm:mx-auto border-gray-700 " />
        <div className="md:flex md:justify-between max-w-screen-xl mx-auto">
          <div className="grid grid-cols-2 gap-8 sm:gap-14 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-white">{t('globals.contact-us')}</h2>
              <ul className="text-gray-400 font-medium">
                <li className="mb-4 flex items-center gap-2 cursor-pointer">
                  <PiPhoneCallLight />
                  <CallButton />
                </li>
                <li className="flex items-center gap-2">
                  <FaWhatsapp onClick={openWhatsApp} className="cursor-pointer" />
                  <FaTelegramPlane onClick={openTelegram} className="cursor-pointer" />
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-white">{t('globals.follow-us')}</h2>
              <ul className="text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="https://www.instagram.com/istudio.am/" target="_blank" rel="noreferrer" className="flex items-center gap-2">
                    <FaInstagram /> <span>Instagram</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="sm:flex sm:items-center sm:justify-end mt-10">
          <span className="text-sm sm:text-center text-gray-400">© 2024 <a href="https://istudio.am/" className="hover:underline">iStudio™</a>. All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};
