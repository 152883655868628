import React from "react";
import {ConfirmationFormCustom, IConfirmationFormCustom} from "../ConfirmationForm/ConfirmationFormCustom";

interface IConfirmationSectionProps {
    formSectionContainerClassName?: string
    formMainWrapperClassName?: string;
    formWrapperClassName?: string;
    confirmationFormProps: IConfirmationFormCustom
}

export const ConfirmationSectionCustom: React.FC<IConfirmationSectionProps> = ({
                                                                                         formSectionContainerClassName,
                                                                                         formMainWrapperClassName,
                                                                                         formWrapperClassName,
                                                                                         confirmationFormProps
                                                                                     }) => {

    return (
        <section className={formSectionContainerClassName}>
            <div
                className={formMainWrapperClassName}>
                <div className={formWrapperClassName}>
                    <ConfirmationFormCustom {...confirmationFormProps} />
                </div>
            </div>
        </section>
    )
}