interface INavbarItems {
  id: number;
  name: string;
  path: string;
}

export const NAVBAR_ITEMS: INavbarItems[] = [
  {
    id: 0,
    name: "home",
    path: "/",
  },
  
  {
    id: 1,
    name: "weblink",
    path: "/weblink",
  },
  {
    id: 2,
    name: "cards",
    path: "/cards",
  },
  {
    id: 3,
    name: "animations",
    path: "/animations",
  }
];
