import React, { useEffect, useState } from 'react'
import { FaArrowUp } from 'react-icons/fa'

export const ToTopButton = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const toggleVisibility = () => {
    if (window.pageYOffset > 600) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    
    window.addEventListener('scroll', toggleVisibility)

    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  return (
    <div className="fixed bottom-6 right-6 z-50">
      <button
        type="button"
        onClick={scrollToTop}
        className={`bg-gray-900 animate-bounce hover:animate-none transition duration-500 ring-1 hover:ring-2 ring-main inline-flex items-center rounded-full p-3 text-main focus:outline-none ${isVisible ? "opacity-100" : "opacity-0"}`}
        
      >
        <FaArrowUp aria-hidden="true" />
      </button>
    </div>
  )
}