import { useState } from 'react';

interface EmailSender {
    loading: boolean;
    error: string | null;
    success: boolean;
    sendEmail: (emailData: EmailData) => Promise<void>;
}

interface EmailData {
    to: string;
    subject: string;
    text: string;
    html?: string;
}

const useEmailSender = (): EmailSender => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);

    const sendEmail = async ({ to, subject, text,html }: EmailData): Promise<void> => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const response = await fetch('https://istudio-backend-57e2q.ondigitalocean.app/send-email', {
            // const response = await fetch('http://localhost:8080/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ to, subject, text, html }),
            });
            if (response.status === 200) {
                setSuccess(true);
                // console.log("Email sent successfully!");
            } else {
                throw new Error('Failed to send email');
            }
        } catch (error) {
            // console.error("Failed to send email:", error);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('An unknown error occurred');
            }
        } finally {
            setLoading(false);
        }
    };

    return { loading, error, success, sendEmail };
};

export default useEmailSender;