import React  from 'react';
import {UseFormRegisterReturn, FieldError} from 'react-hook-form';

interface IInputComponent {
    type: string;
    id: string;
    label?: string;
    placeholder?: string;
    register: UseFormRegisterReturn<string>;
    error?: FieldError | undefined;
    inputContainerClassName?: string;
    inputClassName?: string;
    labelClassName?: string;
    errorClassName?: string;
    options?: { label: string; value: string }[]; // For radio buttons or select dropdowns
    radioContainerClassName?: string;
    radioMainContainerClassName?:string
}

export const InputComponent: React.FC<IInputComponent> = ({
                                                              type,
                                                              id,
                                                              label,
                                                              placeholder,
                                                              register,
                                                              error,
                                                              inputContainerClassName,
                                                              inputClassName,
                                                              labelClassName,
                                                              errorClassName,
                                                              options,
                                                             radioMainContainerClassName, radioContainerClassName
                                                          }) => {

    const renderInput = () => {
        switch (type) {
            case 'text':
            case 'password':
            case 'number':
            case 'email':
                return (
                    <input
                        type={type}
                        id={id}
                        {...register}
                        className={inputClassName}
                        placeholder={placeholder}
                    />
                );
            case 'textarea':
                return (
                    <textarea
                        id={id}
                        {...register}
                        className={inputClassName}
                        placeholder={placeholder}
                    />
                );
            case 'radio':
                return (
                    <div className={radioMainContainerClassName}>
                        {options?.map(option => (
                            <div key={option.value} className={radioContainerClassName}>
                                <input
                                    type="radio"
                                    id={`${id}-${option.value}`}
                                    value={option.value}
                                    // checked={register.name === option.value} // Use register.checked for radio buttons
                                    {...register}
                                    className={inputClassName}
                                />
                                <label className={labelClassName}
                                       htmlFor={`${id}-${option.value}`}>{option.label}</label>
                            </div>
                        ))}
                    </div>
                );
            case 'select':
                return (
                    <select
                        id={id}
                        {...register}
                        className={inputClassName}
                    >
                        {options?.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                );
            default:
                return null;
        }
    };

    return (
        <div className={inputContainerClassName}>
            {renderInput()}
            {label && <label htmlFor={id} className={labelClassName}>{label}</label>}
            {error && <span className={errorClassName}>{error.message}</span>}
        </div>
    );
};



