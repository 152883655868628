import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import enTranslations from './../assets/locales/en/translation.json';
import amTranslations from './../assets/locales/am/translation.json';
import ruTranslations from './../assets/locales/ru/translation.json';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: localStorage.getItem('i18nextLngMainSite') || 'am',
        fallbackLng: 'am',
        // debug: true,
        interpolation: {
            escapeValue: false,
        },
        resources: {
            am: { translation: amTranslations },
            en: { translation: enTranslations },
            ru: { translation: ruTranslations },
        },
        supportedLngs: ['am', 'en', 'ru'],
    });

export default i18n;
