import React from 'react'
import { ConfirmationSectionCustom } from '../ConfirmationSection/ConfirmationSectionCustom'
import { useTranslation } from 'react-i18next';

export const TestComponent = () => {
    const { t } = useTranslation();

    const FORM_DATA = {
        title: "Խնդրում ենք հաստատել Ձեր ներկայությունը մինչև հունիսի 18-ը",
        guestName: t('form.name-surname'),
        guestsNumber: t('form.guests-number'),
        yesText: t('form.yes-01'),
        toMessage: t('form.to-message'),
        noText: t('form.no-01'),
        confirmText: t('form.confirm'),
        ornamentClassName: "h-36 transform scale-x-[-1] filter invert mb-6 mr-6",
        additionalMail: false,
        defaultMail: "unknown110289@gmail.com",
        loadingButtonMessage: t('form-messages.is-sending'),
        classNames: {
            mainContainerClassName: "",
            containerClassName: "relative pb-8 lg:py-16 px-3 xs:px-4 lg:px-16 mx-auto max-w-screen-md rounded-lg",
            titleClassName: "mb-10 lg:mb-10 text-2xl text-center text-gray-700",
            formClassName: "space-y-8",
            inputClassName: "block py-2.5 px-0 w-full text-lg bg-transparent border-0 border-b border-gray-500 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 peer",
            labelClassName: "absolute text-lg duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto",
            inputContainerClassName: "relative z-0",
            errorClassName: "text-red-300",
            radioInputClassName: "w-4 h-4 bg-gray-100 border-gray-300 accent-gray-700 focus:outline-1 focus:outline-white",
            radioMainContainerClassName: "mb-4",
            separatorClassName: "border-t pt-5 border-gray-300",
            radioLabelClassName: "ms-2 text-lg cursor-pointer",
            buttonClassName: "py-2 px-5 text-base text-center rounded-full border border-gray-500 bg-transparent sm:w-fit focus:ring-4 focus:outline-none focus:ring-white hover:scale-105",
            radioContainerClassName: "mb-2"
        }
    };
    return (
        <div>
            <ConfirmationSectionCustom
                formSectionContainerClassName={''}
                formMainWrapperClassName={'w-full flex-wrap gap-6 flex justify-evenly m-auto items-center max-w-screen-xl px-4 py-8 lg:gap-8 xl:gap-0 '}
                confirmationFormProps={FORM_DATA}
            />
        </div>
    )
}
