import React from "react";
import {useTranslation} from "react-i18next";
import useEmailSender from "../../../hooks/useEmailSender";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {InputComponent} from "../../Inputs/InputComponent";
import {CustomButton} from "../../Buttons/CustomButton";

export interface IConfirmationFormCustom {
    title: string;
    guestName: string;
    guestsNumber: string;
    yesText: string;
    toMessage?: string
    noText: string;
    confirmText: string;
    ornament?: string;
    ornamentClassName?: string;
    brideGroom?: IBrideGroomProps;
    additionalMail?: boolean;
    defaultMail?: string;
    loadingButtonMessage: string
    classNames?: IClassNames
    date?: boolean;
    tillDate?: string;
    additionalTextBottom?: boolean;
    additionalText?: string;

}

interface IClassNames {
    mainContainerClassName: string;
    containerClassName: string;
    titleClassName: string;
    formClassName: string;
    inputClassName: string;
    labelClassName: string;
    inputContainerClassName: string;
    errorClassName: string;
    radioContainerClassName?: string;
    separatorClassName: string;
    radioInputClassName: string;
    radioLabelClassName: string;
    radioMainContainerClassName: string;
    buttonClassName: string;
    buttonWrapper?: string;
    tillDateClassName?: string;
    additionalTextClassName?: string;
}

interface ConfirmationFormData {
    name: string;
    guestsNumber?: string | undefined;
    response: string;
    emailTo?: string | undefined;
}

interface IBrideGroomProps {
    groomEmail: string;
    brideEmail: string;
    groomName: string;
    brideName: string;
}

export const ConfirmationFormCustom: React.FC<IConfirmationFormCustom> = ({
                                                                              title,
                                                                              guestName,
                                                                              guestsNumber,
                                                                              yesText,
                                                                              toMessage,
                                                                              noText,
                                                                              confirmText,
                                                                              ornament,
                                                                              ornamentClassName,
                                                                              brideGroom,
                                                                              additionalMail,
                                                                              defaultMail,
                                                                              loadingButtonMessage,
                                                                              classNames,
                                                                              date,
                                                                              tillDate,
                                                                              additionalText,
                                                                              additionalTextBottom
                                                                          }) => {

    const {t} = useTranslation();

    const schema = yup.object().shape({
        name: yup.string().required(t('form-messages.required')).min(2, t('form-messages.name-validation')),
        guestsNumber: yup.string().test('is-number', t('form-messages.guest-number'), (value) => {
            if (!value || value.trim() === '') return true;
            return !isNaN(Number(value)); // Check if value is a number
        }),
        response: yup.string().required(t('form-messages.choose-option')),
        emailTo: additionalMail ? yup.string().required(t('form-messages.choose-option')) : yup.string(),
    });

    const {
        register,
        handleSubmit,
        formState: {errors}, reset
    } = useForm({
        resolver: yupResolver(schema),
    });
    const {loading, error, success, sendEmail} = useEmailSender();

    const onSubmit = async (data: ConfirmationFormData) => {

        try {
            let responseText = data.response === "Yes" ? "Այո" : "Ոչ";
            let guestsMessage = data.guestsNumber ? `${data.guestsNumber}` : '';
            let sendEmailTo = data.emailTo === 'groom'
                ? brideGroom?.groomEmail || defaultMail
                : data.emailTo === 'bride'
                    ? brideGroom?.brideEmail || defaultMail
                    : defaultMail;
            const message = `
        iStudio
      `;

            await sendEmail({
                to: sendEmailTo ? sendEmailTo : '',
                subject: "Մասնակցության հաստատում",
                text: message,
                html: `
                        <h2>Մասնակցության հաստատում</h2>
                        <hr>
                            <p><strong>Անուն, Ազգանուն:</strong> ${data.name}</p>
                            <p><strong>Հյուրերի քանակ:</strong> ${guestsMessage}</p>
                            <p><strong>Կներկայանա:</strong> ${responseText}</p>
                        `
            });
            reset();
        } catch (error) {
            // console.error("Failed to send email:", error);
        }
    };

    return (
        <div className={classNames?.mainContainerClassName}>
            <div className={classNames?.containerClassName}>
                <h2 className={classNames?.titleClassName}>
                    {title}
                </h2>
                {date && (
                    <p className={classNames?.tillDateClassName}>{tillDate}</p>
                )}
                <form onSubmit={handleSubmit(onSubmit)} className={classNames?.formClassName}>
                    <InputComponent
                        type="text"
                        id="name"
                        label={guestName}
                        placeholder=" "
                        register={register("name")}
                        error={errors.name}
                        inputClassName={classNames?.inputClassName}
                        labelClassName={classNames?.labelClassName}
                        inputContainerClassName={classNames?.inputContainerClassName}
                        errorClassName={classNames?.errorClassName}
                    />
                    <InputComponent
                        type="text"
                        id="number"
                        label={guestsNumber}
                        placeholder=" "
                        register={register("guestsNumber")}
                        error={errors.guestsNumber}
                        inputClassName={classNames?.inputClassName}
                        labelClassName={classNames?.labelClassName}
                        inputContainerClassName={classNames?.inputContainerClassName}
                        errorClassName={classNames?.errorClassName}
                    />
                    <InputComponent
                        type="radio"
                        id="name-radio-1"
                        placeholder=" "
                        register={register("response")}
                        error={errors.response}
                        inputClassName={classNames?.radioInputClassName}
                        labelClassName={classNames?.radioLabelClassName}
                        radioContainerClassName={classNames?.radioContainerClassName}
                        options={[
                            {label: yesText, value: "Yes"},
                            {label: noText, value: "No"}
                        ]}
                        errorClassName={classNames?.errorClassName}
                    />
                    {additionalMail && (
                        <div className={classNames?.separatorClassName}>
                            <div className="mb-5">{toMessage}</div>
                            <InputComponent
                                type="radio"
                                id="email-to-radio-1"
                                placeholder=" "
                                register={register("emailTo")}
                                error={errors.emailTo}
                                inputClassName={classNames?.radioInputClassName}
                                labelClassName={classNames?.radioLabelClassName}
                                radioContainerClassName={classNames?.radioContainerClassName}
                                options={[
                                    {label: brideGroom?.groomName || '', value: "groom"},
                                    {label: brideGroom?.brideName || '', value: "bride"}
                                ]}
                                errorClassName={classNames?.errorClassName}
                            />
                        </div>
                    )}
                    <CustomButton buttonWrapper={classNames?.buttonWrapper}
                                  text={confirmText}
                                  loadingButtonMessage={loadingButtonMessage}
                                  className={classNames?.buttonClassName} disabled={loading}
                                  type="submit"/>
                    {error && <p className="text-red-300">{t('form-messages.try-again')}</p>}
                    {success && <p className="text-green-900">{t('form-messages.sent')}</p>}
                </form>
                {additionalTextBottom && (
                    <p className={classNames?.additionalTextClassName}>{additionalText}</p>
                )}
            </div>
        </div>
    );
};